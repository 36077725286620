import * as React from 'react';
import { Header, GoldTopper } from './atoms';

export function GoldToppedHeader({ header, topper, small }) {
  return (
    <>
      <GoldTopper>{topper}</GoldTopper>
      <Header small={small}>{header}</Header>
    </>
  );
}
